<template>
    <div class="home">
        <section>
            <banner-homepage></banner-homepage>
        </section>
        <!-- <section>
            <homepage-banner></homepage-banner>
        </section> -->
        <section class="search-section">
            <search-section></search-section>
        </section>
        <section>
            <three-tier-system></three-tier-system>
        </section>
        <section>
            <details-counter></details-counter>
        </section>
        <section>
            <our-facilities></our-facilities>
        </section>
        <section>
            <awards-and-certifications></awards-and-certifications>
        </section>
    </div>
</template>

<script>
// import HomepageBanner from './components/HomepageBanner.vue';
import AwardsAndCertifications from './components/AwardsAndCertifications.vue';
import DetailsCounter from './components/DetailsCounter.vue';
import OurFacilities from './components/OurFacilities.vue';
import SearchSection from './components/SearchSection.vue';
import ThreeTierSystem from './components/ThreeTierSystem.vue';
import BannerHomepage from './components/BannerHomepage.vue';

export default {
    name: 'Home',
    components: { SearchSection, ThreeTierSystem, DetailsCounter, OurFacilities, AwardsAndCertifications, BannerHomepage }
};

</script>
<style scoped>
/* .search-section{
    margin-top:120rem;
} */
</style>
