<template>
    <section class="py-5">
        <div class="container">
            <h2 class="montserrat-bold mb-3 font-weight-bold" v-if="heading!==''">{{ heading }}</h2>
            <div class="mb-3" v-else>
                <slot name="heading"></slot>
            </div>
            <section class="mb-4">
                <slot name="content"></slot>
            </section>
            <footer>
                <slot name="footer"></slot>
            </footer>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DetailsInner',
    props: {
        heading: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
footer {

    a {
        text-decoration: none;
    }

}
</style>
