<template>
    <div>
        <bull-search-banner :showSearch="showSearch" :searchBull="search" @searchBull="setSearch"></bull-search-banner>
        <filter-section @detailsClicked="detailsClicked" @detailsClosed="detailsClosed" @clear="clearSearch"
                        :search="search"></filter-section>
    </div>
</template>

<script>
import BullSearchBanner from './components/bull-search/BullSearchBanner.vue';
import FilterSection from './components/bull-search/FilterSection.vue';

export default {
    components: { BullSearchBanner, FilterSection },
    name: 'BullSearch',
    data () {
        return {
            showSearch: true,
            search: '',
            homeSearch: this.$route.params.search
        };
    },
    methods: {
        detailsClicked (response) {
            this.showSearch = false;
            this.$emit('detailsClicked', response);
        },
        detailsClosed (response) {
            this.showSearch = true;
        },
        setSearch (response) {
            this.search = response;
        },
        clearSearch () {
            this.search = '';
        }
    },
    beforeMount () {
        if (this.homeSearch) {
            this.search = this.homeSearch;
        }
    }
};
</script>

<style>

</style>
