<template>
    <data-loading-animation class="pt-9 pb-6 bg-info-details bg-gray-1" v-if="dataLoading"/>
    <section v-else class="pt-9 pb-6 bg-info-details bg-gray-1">
        <div class="overlay-dark" v-if="districtInfo" @click="districtInfo = !districtInfo"></div>
        <div class="container">
            <div class="fl-te-c mb-5">
                <h4 class="text-center text-uppercase oswald-600">Bull Details</h4>
                <btn size="sm" icon="fa fa-caret-left" text="Back" @click="$emit('back')"/>
            </div>
            <div id="copy-text" class="row">
                <div class="col-lg-6">
                    <div class="mt-1">
                        <img style="min-height: 28rem" v-if="details.file" :src="'/upload/' + details.file" alt=""
                             class="w-100 m-0 p-0 round-3 of-h">
                        <img style="min-height: 28rem" v-else src="../assets/img/bg/green.png" alt=""
                             class="w-100 m-0 p-0 round-3 of-h">
                        <div class="mb-3 mt-4 d-none d-lg-block">
                            <btn border-radius="2" class="px-lg-6 py-3 mb-3 mb-md-0" icon-alignment="right"
                                 icon="fa fa-download" :loading="downloadLoading" loading-text="Downloading..."
                                 text="Download PDF" @click="downloadBull"/>
                            <br class="d-md-none">
                            <div class="d-inline-block ml-md-5">
                                <span class="mr-5">share</span>
                                <btn border-radius="2" class="p-3 mr-3 d-sm-none" icon-alignment="right"
                                     icon="fa fa-whatsapp" @click="shareWhatsapp"></btn>
                                <!--                                <a href="whatsapp://send?text=This is WhatsApp sharing example using link"-->
                                <!--                                   data-action="share/whatsapp/share"-->
                                <!--                                   target="_blank" class="lego-btn p-3 mr-3 btn-basic btn-color-primary round-2 bs-1 bs-1-h d-lg-none"><i class="fa fa-whatsapp"></i></a>-->

                                <btn @click="copyURL" border-radius="2" class="p-3" icon-alignment="right"
                                     icon="fa fa-chain"></btn>
                                <!--                                <a href="whatsapp://send?text=GFG Example for whatsapp sharing"-->
                                <!--                                   data-action="share/whatsapp/share" target="_blank">Share to whatsapp</a>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="details" class="col-lg-6">
                    <div class="fl-te-c mt-4 mt-lg-0 mb-5 mb-lg-4">
                        <h2 v-if="details.name" class="text-uppercase text-primary oswald-600 mb-0 heading-type-1">{{
                                details.name
                            }}</h2>
                        <h2 v-else class="text-uppercase text-primary oswald-600 mb-0 heading-type-1">Bull -
                            {{ details.id }}</h2>
                        <div class="d-lg-none">
                            <btn border-radius="2" class="p-3 mr-3" icon-alignment="right"
                                 icon="fa fa-download" @click="downloadBull"></btn>
                            <div class="d-inline-block">
                                <!--                                <btn border-radius="2" class="p-3 mr-3" @click="shareWhatsapp"-->
                                <!--                                     icon-alignment="right"-->
                                <!--                                     icon="fa fa-whatsapp"></btn>-->
                                <ShareNetwork class="lego-btn p-3 mr-3 btn-basic btn-color-primary round-2 bs-1 bs-1-h"
                                              network="whatsapp"
                                              :url="getWaShareLink()"
                                >
                                    <i class="fa fa-whatsapp"></i>
                                </ShareNetwork>

                                <btn border-radius="2" @click="copyURL" class="p-3" icon-alignment="right"
                                     icon="fa fa-chain"></btn>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="details.bullno" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Bull No.</p>
                            <p class="mb-0 ml-1 poppins-500">{{ details.bullno }}</p>
                        </div>
                        <div v-if="details.breed" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Breed</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.breed.name }}</p>
                        </div>
                        <div v-if="details.species" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Species</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.species.name }}</p>
                        </div>
                        <div v-if="details.sirenumber" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Sire No.</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.sirenumber }}</p>
                        </div>
                        <div v-if="details.uid" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">UID</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.uid }}</p>
                        </div>
                        <div v-if="details.dob" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Date of Birth</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.dob }}</p>
                        </div>
                        <div v-if="details.damnumber" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Dams No.</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.damnumber }}</p>
                        </div>
                        <div v-if="details.dam_yeild" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Dams Yeild</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.dam_yeild }}</p>
                        </div>
                        <div v-if="details.sire_daughter_yield" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Sire's Daughters Yield</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.sire_daughter_yield }}</p>
                        </div>
                        <div v-if="details.milkfat" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Milk Fat %</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.milkfat }}</p>
                        </div>
                        <div v-if="details.milkprotein" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Milk Protien</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.milkprotein }}</p>
                        </div>
                        <div v-if="details.breeding_value" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Breeding Value</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.breeding_value }}</p>
                        </div>
                        <div v-if="details.source" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">Source</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.source }}</p>
                        </div>
                        <div v-if="details.inaph_code" class="col-6 col-md-4 mb-3">
                            <p class="mb-0">INAPH Code</p>
                            <p class="ml-1 mb-0 poppins-500">{{ details.inaph_code }}</p>
                        </div>
                    </div>
                    <div v-if="details.district" class="row">
                        <div class="col col-md-12 mb-3">
                            <div class="fl-x fl-a-c pos-r">
                                <p class="mb-0 mr-2">Available District</p>
                                <span><btn @click="districtInfo = !districtInfo" size="sm" design="basic-b"
                                           icon="fa fa-question"></btn></span>
                                <div class="d-abs-info card bs-4 py-3" v-if="districtInfo">
                                    <h5 class="mb-3">Bull Allotment Programme</h5>
                                    <p class="fs--1">
                                        Entire bulls of KLDB is divided into 3 groups/ families based on their pedigree
                                        so
                                        that different groups have genetically unrelated bulls. Also our state is
                                        divided
                                        into 3 zones and is supplied with semen of bulls belonging to any one group for
                                        a
                                        period of 3 yrs. After that period same area will be supplied with semen from
                                        bulls
                                        of another group. This is done so as to prevent chances of inbreeding (i.e
                                        animals
                                        being bred with semen of related animals like progeny of a bull being
                                        inseminated
                                        with semen of same bull) and to ensure genetic diversity at any given area &
                                        time.
                                    </p>

                                </div>
                            </div>

                            <span class="poppins-500" v-for="(i, index) in details.district" v-bind:key="i">
                                <span v-if="index !== (details.district.length - 1)">{{ i + ' , ' }}</span>
                                <span v-else>{{ i }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    name: 'BullInfo',
    data () {
        return {
            dataLoading: false,
            downloadLoading: false,
            details: null,
            districtInfo: false
            // waShareLink: ''
        };
    },
    // computed: {
    //     waShareLink () {
    //         return window.location.href + this.details.name;
    //     }
    // },
    mounted () {
        this.loadDetails();
        // this.waShareLink = 'whatsapp://send?text=' + 'https://kldb.xeoscript.com/our-bulls/' + this.details.name;
        // this.waShareLink = 'https://kldb.xeoscript.com/our-bulls/' + this.details.name;
    },
    methods: {
        shareWhatsapp () {
            const message = 'https://kldb.xeoscript.com/our-bulls/' + this.details.name;
            window.open('https://web.whatsapp.com/send?text=' + message);
        },
        getWaShareLink () {
            if (this.details) {
                // return window.location.href + this.details.name;
                return 'https://kldb.xeoscript.com/our-bulls/' + this.details.name;
            }
        },
        copyURL () {
            const text = document.createElement('input');
            document.body.appendChild(text);
            text.setAttribute('id', 'text_id');
            document.getElementById('text_id').value = 'https://kldb.xeoscript.com/our-bulls/' + this.details.name;
            text.select();
            document.execCommand('copy');
            // document.body.removeChild(text);
            this.$notify('Link Copied', 'Copy', {
                type: 'success'
            });
        },
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.form(urls.admin.bulls.details, { id: that.id });
            const json = response.data;
            if (json.success === true) {
                that.details = json.object;
                if (that.details.district) {
                    that.details.district = that.details.district.split(',');
                }
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        },
        async downloadBull () {
            this.downloadLoading = true;
            const component = this;
            const response = await axios.form(urls.admin.bulls.download, { id: component.id });
            const json = response.data;
            if (json) {
                window.open('https://kldbuat.xeoscript.com/upload/' + json);
                component.$notify('Download Success', 'Success', {
                    type: 'success'
                });
                component.downloadLoading = false;
            } else {
                component.$notify('Could not download, Please try again later', 'Message', {
                    type: 'info'
                });
            }
            component.downloadLoading = false;
        }
    }
};
</script>
<style lang="scss">

.d-abs-info {
    position: absolute;
    transform: translateY(60%);
    //left: 30%;
    z-index: 999;
    background: var(--bg-4);
    transition: all 500ms ease-in-out;
}

.overlay-dark {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 400;
    transition: all 500ms ease-in-out;
}
</style>
