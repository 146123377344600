<template>
    <div class="banner-home" style="">
        <video autoplay muted loop playsinline class="banner-video">
            <source src="../../assets/video/banner-video-1.mp4" type="video/mp4">
        </video>

        <!--        <iframe-->
        <!--            src="https://www.youtube.com/embed/W0LHTWG-UmQ?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&enablejsapi=0&playlist=W0LHTWG-UmQ"-->
        <!--            frameborder="0" allowfullscreen controls=0 loop=0 autohide="1" modestbranding="0" rel="0" showinfo="0"-->
        <!--            disablekb="1" enablejsapi="0" iv_load_policy="3"></iframe>-->

        <!--        <iframe id="existing-iframe-example"-->
        <!--          width="640" height="360"-->
        <!--          src="https://www.youtube.com/embed/-SFcIUEvNOQ?autoplay=1&mute=1&showinfo=0&control=0&enablejsapi=0" allow="autoplay"-->
        <!--          frameborder="0" controls=0-->
        <!--          style="border: solid 4px #37474F"-->
        <!--          ></iframe>-->

        <!--                <video-embed :params="{autoplay: 1}" src="https://www.youtube.com/watch?v=s4ObxcdXoFE"></video-embed>-->
        <div class="dark-overlay"></div>
        <!-- <div class="container"> -->
        <div class="content container">
            <section class="banner-section text-center mt-6">
                <h1 class="text-primary mb-3 oswald-600 text-4x">MILES AHEAD IN THE GENE RACE</h1>
                <p class="mb-5 text-white poppins-500 lt-s-1">Glorious Five Decades of Existence</P>
                <btn @click="$router.push('/our-bulls/')" text="Our Bulls" size="lg" class="px-5 py-3 poppins-500"
                     border-radius="2"></btn>
            </section>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'HomepageBanner',
    data () {
        return {
            dataLoading: false,
            bannerLink: '',
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.get(urls.admin.banner.list);
            const json = response.data;
            if (json) {
                that.details = json.data;
                that.setBanner();
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        },
        setBanner () {
            for (let i = 0; i <= this.details.length; i++) {
                if (this.details[i].type === 'home_page') {
                    this.bannerLink = this.details[i].link;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.banner-home {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) {
        justify-content: flex-end;
    }
    z-index: 2;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.8;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.8;
    }

    .content {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: flex-end;

        .banner-section {
            width: 100%;
            @media (min-width: 992px) {
                width: 45%;
            }
        }
    }

    .dark-overlay {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000026;
        // background: var(--color-primary);
        // mix-blend-mode: overlay;
    }
}

// .banner-video {
//   position: relative;
//   right: 0;
//   bottom: 0;
//   min-width: 100%;
//   // min-height: 100%;
// }
// .content {
//   position: fixed;
//   bottom: 0;
//   // background: rgba(0, 0, 0, 0.5);
//   color: #f1f1f1;
//   width: 100%;
//   /* width: 35%; */
//   height: 100%;
//   padding: 20px;
//   display: flex;
//   // flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   @media (min-width:992px) {
//       justify-content: flex-end;
//   }

//   .banner-section{
//     width:100%;
//     @media (min-width:992px) {
//       width:40%;
//     }
//   }
// }

* {
    box-sizing: border-box;
}

.video-background {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}

@media all and (max-width: 600px) {
    .vid-info {
        width: 50%;
        padding: .5rem;
    }
    .vid-info h1 {
        margin-bottom: .2rem;
    }
}

@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}

</style>
