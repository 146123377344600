<template>
  <div class="bg-primary py-7 bg-green-l bg-cover">
      <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3 border-r">
                    <base-counter :to="details['bull-total']" heading="Bulls" :count="details['bull-total']" class=""
                                    icon="fa fa-window-maximize fa-3x"/>
            </div>
<!--            <div class="col-12 col-lg-4 border-r">-->
<!--                    <base-counter :to="details.breed" heading="Breeds" :count="details.breed" class=""-->
<!--                                    icon="fa fa-window-maximize fa-3x"/>-->
<!--            </div>-->
<!--            <div class="col-12 col-lg-4">-->
<!--                    <base-counter :to="details.species" heading="Species" :count="details.species" class=""-->
<!--                                    icon="fa fa-window-maximize fa-3x"/>-->
<!--            </div>-->
            <div class="col-12 col-lg-3 border-r">
                    <base-counter to="352" heading="Semen Stations" :count="3"
                                    icon="fa fa-window-maximize fa-3x">
                    </base-counter>
            </div>
            <div class="col-12 col-lg-3 border-r">
                    <base-counter to="352" heading="Regional Semen banks" :count="7"
                                    icon="fa fa-window-maximize fa-3x">
                    </base-counter>
            </div>
            <div class="col-12 col-lg-3">
                <base-counter to="352" heading="Field Level AI Units" :count="3000"
                                    icon="fa fa-window-maximize fa-3x">
                </base-counter>
            </div>
        </div>
      </div>

  </div>
</template>

<script>
import BaseCounter from './UI/BaseCounter.vue';
import axios from 'secure-axios';
import urls from '../../data/urls';
export default {
    components: { BaseCounter },
    data () {
        return {
            dataLoading: false,
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.get(urls.admin.dashboard);
            const json = response.data;
            if (json.success === 'true') {
                that.details = json.data;
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
