<template>
    <div class="fl-x mb-3 fl-a-s">
        <i class="fa fa-map-marker fa-2x mr-2" :class="{'text-success':color === 'success', 'text-danger':color === 'danger'}"></i>
        <h5 class="m-0 poppins-500 m-0">{{text}}</h5>
    </div>
</template>

<script>
export default {
    name: 'IconText',
    props: {
        text: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'success'
        }
    }
};
</script>

<style>

</style>
