<template>
    <div v-if="showSearch" :style="image" class="search-banner-bg bg-cover-top banner-sm text-white pt-7 pos-r">
        <div class="dark-overlay"></div>
        <div class="container h-100">
            <div class="fl-y fl-j-sa banner-sm">
                <div class="z-index-3 text-center text-lg-left">
                    <h1 class="oswald-600 text-3x text-uppercase">Search For Your Bull</h1>
                    <p class="poppins-500">You are a few filters away</p>
                </div>
                <div>
                    <validated-input @blur="searchInput" v-model="search" size="lg"
                                     placeholder="Search bull name or number"
                                     class="roboto-regular c-input-white col-lg-5">
                        <template #append>
                            <i class="fa fa-search cursor-pointer z-index-1" @click="searchInput"></i>
                        </template>
                    </validated-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    props: {
        showSearch: {
            type: Boolean,
            default: true
        },
        searchBull: {
            type: String,
            default: ''
        }
    },
    name: 'BullSearchBanner',
    computed: {
        image () {
            return { backgroundImage: 'url(/upload/' + this.bannerImage + ')' };
        }
    },
    data () {
        return {
            search: '',
            BannerLoading: false,
            banners: '',
            bannerImage: ''
        };
    },
    mounted () {
        this.setSearch();
        this.loadBannerDetails();
    },
    watch: {
        // search: {
        //     handler () {
        //         if (this.search === null || this.search === undefined) {
        //             this.search = '';
        //         }
        //         this.$emit('searchBull', this.search);
        //     }
        // },
        searchBull: {
            handler () {
                if (this.searchBull === null || this.searchBull === undefined) {
                    this.search = '';
                }
                this.search = this.searchBull;
            }
        }
    },
    methods: {
        async loadBannerDetails () {
            const that = this;
            that.BannerLoading = true;
            const response = await axios.get(urls.admin.banner.list);
            const json = response.data;
            if (json) {
                that.banners = json.data;
                that.setBanner();
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.BannerLoading = false;
        },
        setBanner () {
            for (let i = 0; i <= this.banners.length; i++) {
                if (this.banners[i].type === 'bulls_page') {
                    this.bannerImage = this.banners[i].file;
                }
            }
        },
        setSearch () {
            if (this.searchBull === null || this.searchBull === undefined) {
                this.search = '';
            }
            this.search = this.searchBull;
        },
        searchInput () {
            if (this.search !== '') {
                this.$emit('searchBull', this.search);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.dark-overlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.68);
}
</style>
