<template>
    <div class="py-5">
        <div class="container">
            <h2 class="mb-5 text-center text-uppercase oswald-600">3 Tier System of Semen Distribution</h2>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <img src="../../assets/img/map/kerala.png" class="img-fluid" alt="">
                    <div class="section-content">
                        <h2 class="oswald-600">03</h2>
                        <h5>Semen Banks</h5>
                        <h2 class="oswald-600">07</h2>
                        <h5>Regional Banks</h5>
                        <h2 class="oswald-600">3000</h2>
                        <h5>Field Level AI Units</h5>
                    </div>
                    <span class="underline-item"></span>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="h-100 fl-y fl-j-c">
                        <div class="mb-5">
                            <h4 class="mb-3 poppins-700">Central Semen Bank</h4>
                            <icon-text text="Central Semen Bank, Mattupatty"></icon-text>
                            <icon-text text="Central Semen Bank, Dhoni"></icon-text>
                            <icon-text text="Central Semen Bank, Kulathupuzha"></icon-text>
                        </div>
                        <div class="mb-5">
                            <h4 class="mb-3 poppins-700">Regional Semen Banks</h4>
                            <icon-text text="Regional Semen Bank, Kannur" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Puthuppadi" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Dhoni" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Chalakudy" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Muvattupuzha" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Mavelikkara" color="danger"></icon-text>
                            <icon-text text="Regional Semen Bank, Kulathupuzha" color="danger"></icon-text>
                        </div>
                        <div class="mb-5">
                            <h3 class="mb-3 poppins-700">3000 Field Level AI Units</h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IconText from './UI/IconText.vue';

export default {
    name: 'ThreeTier',
    components: {
        IconText
    }
};
</script>
<style scoped lang="scss">
.section-content {
    position: absolute;
    bottom: 3.5rem;
    // border-bottom: 1px solid var(--color-primary);
    &::after {
        content: '';
        height: 2px;
        width: 140%;
        background: var(--color-primary);
        position: absolute;
        bottom: -6px;
        @media(min-width: 992px) {
            width: 190%;
        }
    }

    .underline-item {
        height: 1px;
        width: 320px;
        background: var(--color-primary);
    }
}
</style>
