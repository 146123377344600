<template>
    <div>
        <section :style="image" class="bg-contact banner-contact">
            <div class="container h-100 ">
                <div class="row mt-5">
                    <div class="col-lg-6">
                        <h1 class="text-white mb-3 oswald-600 text-4x">FIND US AT</h1>
                        <p class="text-white poppins-500 lt-s-1">Have any Doubts? Contact Us...</P>
                    </div>
                </div>
            </div>
        </section>

        <data-loading-animation class="bg-address pt-5 pb-5 pt-lg-6" v-if="DataLoading"/>
        <section v-else class="bg-address pt-5 pt-lg-6">
            <div class="container">
                <div class="row">
                    <div v-for="(i, index) in details" v-bind:key="index" class="col-md-6 col-lg-4 mb-5 mb-lg-6">
                        <p class="mb-2 text-primary poppins-600 fs-3 text-capitalize">{{ i.semenstation }}</p>
                        <p class="poppins-600 mb-1 fs-2 text-capitalize">Dr. {{  i.name }}</p>
                        <p class="fs--1 mb-2 text-capitalize">{{  i.designation }}</p>
                        <p class="mb-1">
                            <img class="icon-address" src="../assets/img/icons/phone.png" alt="#">
                            <a v-if="i.phone" href="tel:+04752317660" class="text-decoration-none">
                                {{ i.phone }}, </a>
                            <span v-if="i.mobile" class="text-decoration-none"> {{ i.mobile }}</span>
                        </p>
                        <p class="mb-1">
                            <a :href="'mailto:' + i.email" class="text-decoration-none">
                                <img class="icon-address" src="../assets/img/icons/email.png" alt="#">
                                {{ i.email }}
                            </a>
                        </p>
                        <p @click="goToLocation(i)" class="mb-0">
                            <a class="text-decoration-none text-capitalize" target="_blank"
                               href="https://goo.gl/maps/YivgidzBbrhx1QyW8">
                                <img class="icon-address" src="../assets/img/icons/location.png" alt="#">
                                {{ i.semenstation }}, {{i.pincode}}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-6">
            <div class="container">
                <h2 class="text-uppercase oswald-600 mb-5">Contact Us</h2>
                <transition name="fade" mode="out-in">
                    <div class="alert alert-success" v-if="state === 'S'" key="'S">
                        <div class="text-center p-4">
                            <svg style="width: 100px;" version="1.1" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
                                        stroke-miterlimit="10"
                                        cx="65.1" cy="65.1" r="62.1"/>
                                <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
                                          stroke-linecap="round"
                                          stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                            </svg>
                            <p class="c-font-19 mb-4 proxim-light">
                                The mail has been successfully submitted.
                            </p>

                            <button @click="$router.push('/')" class="lego-btn px-5 py-3 bg-primary round-2"
                                    type="submit"> Home
                            </button>
                        </div>
                    </div>
                    <div class="alert alert-danger" v-else-if="state === 'F'" key="'F">
                        <div class="text-center p-4">
                            <svg style="width: 100px;" version="1.1" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6"
                                        stroke-miterlimit="10"
                                        cx="65.1" cy="65.1" r="62.1"/>
                                <line class="path line" fill="none" stroke="#D06079" stroke-width="6"
                                      stroke-linecap="round"
                                      stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                <line class="path line" fill="none" stroke="#D06079" stroke-width="6"
                                      stroke-linecap="round"
                                      stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                            </svg>
                            <p class="c-font-19 mb-4 proxim-light">
                                Server error , Please try after sometime.
                            </p>

                            <button @click="state='D'" class="lego-btn px-5 py-3 bg-primary round-2"
                                    type="submit"> Try again
                            </button>
                        </div>
                    </div>
                    <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                        <div class="text-center p-4">
                            <loading-animation/>
                            <p class="c-font-19 mb-4 proxim-light">Please wait while communicate
                                with the server</p>
                        </div>
                    </div>
                    <s-form ref="form" class="contact-form row" v-else role="form" @submit="sendEmail"
                            autocomplete="off">
                        <div class="col-12 col-lg-4 mb-3">
                            <validated-input class="c-input-1" border-radius="2" name="Name" v-model="model.name" shadow="0"
                                             placeholder="Name" :rules="rules.name"
                                             required/>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <validated-mask-input class="c-input-1" border-radius="2" name="Phone" v-model="model.phone"
                                                  shadow="0"
                                                  mask="##########" placeholder="Phone"
                                                  :rules="rules.phone_number"/>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <validated-input class="c-input-1" border-radius="2" type="email" name="Email"
                                             v-model="model.email" shadow="0"
                                             placeholder="Email" :rules="rules.email_id"/>
                        </div>
                        <div class="col-12 mb-3">
                            <validated-text-area class="c-input-1" border-radius="2" placeholder="Message"
                                                 v-model="model.message"
                                                 :rules="rules.message1" shadow="0"/>
                        </div>
                        <div class="mt-4 col-12">
                            <button class="lego-btn px-5 py-3 bg-primary round-2"
                                    type="submit">
                                SUBMIT
                            </button>
                        </div>
                    </s-form>
                </transition>
            </div>
        </section>

    </div>
</template>

<script>
import axios from 'secure-axios';
import LoadingAnimation from './components/LoadingAnimation';
import urls from '../data/urls';

export default {
    name: 'Contact',
    components: { LoadingAnimation },
    computed: {
        image () {
            return { backgroundImage: 'url(/upload/' + this.bannerImage + ')' };
        }
    },
    data () {
        return {
            DataLoading: false,
            details: '',
            BannerLoading: false,
            banners: '',
            bannerImage: '',
            listUrl: urls.admin.bulls.list,
            state: 'D',
            model: {},
            message: '',
            rules: {
                name: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                message1: {
                    required: true
                }
            }
        };
    },
    mounted () {
        this.loadBannerDetails();
        this.loadDetails();
    },
    methods: {
        goToLocation (item) {
            if (item.latitude === '' || item.longitude === '') {
                window.open('https://www.google.com/maps/place/' + item.semenstation);
            } else {
                window.open('https://www.google.com/maps/place/' + item.latitude + ',' + item.longitude);
            }
        },
        async loadBannerDetails () {
            const that = this;
            that.BannerLoading = true;
            // const response = await axios.get(urls.admin.banner.list);
            const response = await axios.get(urls.admin.banner.list);
            const json = await response.data;
            if (json) {
                that.banners = json.data;
                that.setBanner();
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.BannerLoading = false;
        },
        setBanner () {
            for (let i = 0; i <= this.banners.length; i++) {
                if (this.banners[i].type === 'contact_page') {
                    this.bannerImage = this.banners[i].file;
                }
            }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.get(urls.admin.contact.list);
            const json = response.data;
            if (json) {
                that.details = json.data;
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.DataLoading = false;
        },
        async sendEmail () {
            this.state = 'L';
            const response = await axios.form(urls.admin.contact.sendEmail, this.model);
            const json = response.data;
            if (json.error === false) {
                this.state = 'S';
                this.model = {};
            } else {
                this.state = 'F';
                this.model = {};
            }
        }
    }
};
</script>

<style scoped lang="scss">
.banner-contact {
    min-height: 70vh;
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
        min-height: 100vh;
    }
}

.bg-address {
    background-image: url("../assets/img/bg/bg-02.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contact {
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-address {
    width: 15px;
    margin-right: 10px;
}
</style>
