<template>
    <div class="bg-green bg-cover search-section py-5 text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 border-r">
                    <div class="w-100 fl-x fl-j-c fl-a-c">
                        <details-inner class="w-100">
                            <template #heading>
                                <h2 class="text-2x oswald-600">SEARCH FOR YOUR BULL</h2>
                            </template>
                            <template #content>
                                <p>Search through the best bulls</p>
                            </template>
                            <template #footer>
                                <!-- <btn title="Button"></btn> -->
                                <!-- <input type="text"> -->
                                <validated-input v-model="search" size="lg" placeholder="Search bull name or number"
                                                 class="roboto-regular c-input-white c-input-lg w-90">
                                    <template #append>
                                        <btn @click="searchClicked" text=" Search"
                                             class="fa fa-search bl-3 pl-2"></btn>
                                    </template>
                                </validated-input>
                            </template>
                        </details-inner>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="w-100 fl-x fl-j-c fl-a-c">
                        <details-inner>
                            <template #heading>
                                <h2 class="text-2x oswald-600">CHOOSE YOUR BULL NOW</h2>
                            </template>
                            <template #content>
                                <p>Click on the button below</p>
                            </template>
                            <template #footer>
                                <btn @click="$router.push('/our-bulls/')" title="Our Bulls" :shadow="30"
                                     class="px-5 btn-light" :border-radius="2" size="lg"></btn>
                            </template>
                        </details-inner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailsInner from './UI/DetailsInner';

export default {
    name: 'SearchSection',
    components: {
        DetailsInner
    },
    data () {
        return {
            search: ''
        };
    },
    methods: {
        searchClicked () {
            if (this.search) {
                this.$router.push('/our-bulls/' + this.search + '/');
            }
        }
    }
};
</script>

<style scoped>
.search-section {
    margin-top: 100vh;
}
</style>
