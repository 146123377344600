<template>
    <div v-if="!detailsClicked">
        <div id="tableTop" class="bg-green-l bg-cover">
            <div class="container py-6">
                <s-form class="row">
                    <div class="col-12 col-lg-4 fl-x-c mb-4 mb-lg-0">
                        <h2 class="text-uppercase text-white oswald-600 mb-0 heading-type-1">Advanced Filters</h2>
                    </div>
                    <div class="col-6 col-lg-2 ml-lg-auto mb-3 mb-lg-0">
                        <validated-ajax-vue-select class="c-input-white" :url="speciesOptions" v-model="species"
                                                   placeholder="Species" name="Species"/>
                    </div>
                    <div class="col-6 col-lg-2 mb-3 mb-lg-0">
                        <validated-ajax-vue-select class="c-input-white" :url="breedOptions" v-model="breed"
                                                   placeholder="Breed" name="Breed"/>
                    </div>
                    <div class="col-12 col-lg-2 mb-3 mb-lg-0">
                        <validated-vue-select class="c-input-white" v-model="damsYield" placeholder="Dam's Yield"
                                              name="Dam's Yield" :options="yieldOptions"/>
                    </div>
                    <div class="col-6 col-lg flex-grow-0-lg">
                        <button type="submit"
                                class="lego-btn px-4 py-3 btn-basic btn-color-danger round-1 bs-1 bs-1-h d-block">
                            <span @click="clearFilter" class="">Clear Filter</span>
                        </button>
                    </div>
                </s-form>
            </div>
        </div>
        <section class="bg-gray-1 bg-info-details-web">
            <div class="container py-4 py-lg-5" style="overflow-x:auto;">
                <data-loading-animation v-if="DataLoading"/>
                <div v-else>
                    <table class="table c-table-2 c-table mb-0 w-100 border-r-2">
                        <thead class="py-3">
                        <tr>
                            <th></th>
                            <th>Bull No.</th>
                            <th>Bull Name</th>
                            <th>Species</th>
                            <th>Breed</th>
                            <th>Dam's Yield</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody v-if="details.length!==0">
                        <tr v-for="(i, index) in details" v-bind:key="index" class="bg-info-details-mob">
                            <td class="table-img">
                                <img v-if="i.file" style="min-height: 9rem;" :src="'/upload/' + i.file" alt=""
                                     class="w-100 m-0 p-0 round-2 of-h">
                                <img v-else style="min-height: 9rem;" src="../../../assets/img/bg/green.png" alt=""
                                     class="w-100 m-0 p-0 round-2 of-h">
                            </td>
                            <td data-label="Bull No.">{{ i.bullno }}</td>
                            <td data-label="Bull Name">{{ i.name }}</td>
                            <td data-label="Species">{{ i.species.name }}</td>
                            <td data-label="Breed">{{ i.breed.name }}</td>
                            <td data-label="Dam's Yield">{{ i.dam_yeild }}</td>
                            <td>
                                <p @click="viewDetails(i.id)"
                                   class="text-primary text-decoration-none poppins-600 cursor-pointer">view</p>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="bg-info-details-mob">
                            <td colspan="12" class="text-primary text-center card p-5">No bulls to list</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="fl-x fl-x-cc">
                        <div class="btn-group-tight">
                            <!--                            <btn v-if="current_page!==1" class="btn-basic-b fa fa-chevron-left" @click="previousPage"/>-->
                            <!--                            <btn>{{ current_page }}</btn>-->
                            <!--                            <btn v-if="current_page!==last_page" class="btn-basic-b fa fa-chevron-right"-->
                            <!--                                 @click="nextPage"/>-->
                            <div v-if="pagination_sec_count > 1 && perfect_pagination">
                                <btn v-if="curr_pagination_sec>1" size="sm" class="fa fa-chevron-left mr-1 mr-lg-2"
                                     @click="previousPaginationSection"/>
                                <template>
                                    <btn design="basic-b" size="sm" class="mr-1 mr-lg-2" :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }" v-for="i in ( curr_pagination_sec *5 - (curr_pagination_sec -1) * 5 )"
                                         @click="gotoPage(i + (curr_pagination_sec - 1) * 5)" :key="i">
                                        {{ i + (curr_pagination_sec - 1) * 5 }}
                                    </btn>
                                </template>

                                <!--                                <btn v-for="i in ( pagination_upper_limit - pagination_lower_limit )"-->
                                <!--                                     @click="gotoPage(i)" :key="i">{{ i + (curr_pagination_sec - 1) * 5 }}-->
                                <!--                                </btn>-->
                                <btn v-if="curr_pagination_sec < pagination_sec_count"
                                     class="fa fa-chevron-right" size="sm"
                                     @click="nextPaginationSection"/>
                            </div>
                            <div v-else-if="pagination_sec_count > 1 && !perfect_pagination">
                                <btn v-if="curr_pagination_sec >1" class="fa fa-chevron-left mr-1 mr-lg-2"
                                     @click="previousPaginationSection" size="sm"/>
                                <template v-if="curr_pagination_sec <= pagination_sec_count">
                                    <btn class="mr-1 mr-lg-2" size="sm" :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }" design="basic-b" v-for="i in ( curr_pagination_sec *5 - (curr_pagination_sec -1) * 5 )"
                                         @click="gotoPage(i + (curr_pagination_sec - 1) * 5)" :key="i">
                                        {{ i + (curr_pagination_sec - 1) * 5 }}
                                    </btn>
                                </template>
                                <template v-else-if="curr_pagination_sec > pagination_sec_count ">
                                    <btn class="mr-1 mr-lg-2" size="sm" :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }" design="basic-b" v-for="i in ( last_page - (curr_pagination_sec -1) * 5 )"
                                         @click="gotoPage(i + (curr_pagination_sec - 1) * 5)" :key="i">
                                        {{ i + (curr_pagination_sec - 1) * 5 }}
                                    </btn>
                                </template>
                                <btn v-if="curr_pagination_sec < pagination_sec_count + 1"
                                     class="fa fa-chevron-right" size="sm"
                                     @click="nextPaginationSection"/>
                            </div>
                        </div>
<!--                        <div>-->
<!--                            <span class="text-muted">Page</span>-->
<!--                            {{ current_page }}-->
<!--                            <span class="text-muted">of</span>-->
<!--                            {{ last_page }}-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </section>
    </div>
    <bull-info @back="backClicked" v-else :id="bullID"/>
</template>
<script>

import urls from '../../../data/urls';
import axios from 'secure-axios';
import BullInfo from '../../BullInfo';

export default {
    components: { BullInfo },
    props: {
        search: {
            type: String,
            default: ''
        }
    },
    name: 'FilterSection',

    data () {
        return {
            DataLoading: false,
            detailsClicked: false,
            current_page: 1,
            last_page: '',
            pagination_sec_count: 0,
            perfect_pagination: true,
            curr_pagination_sec: 1,
            pagination_lower_limit: null,
            pagination_upper_limit: null,
            per_page: 5,
            bullID: '',
            damsYield: '',
            breed: '',
            from: '',
            to: '',
            details: '',
            species: '',
            listUrl: urls.admin.bulls.list,
            yieldOptions: [
                { value: 1, label: '2000 - 4000' },
                { value: 2, label: '4000 - 6000' },
                { value: 3, label: '6000 - 8000' },
                { value: 4, label: '8000 - 10000' },
                { value: 5, label: '10000 and above' }
            ],
            speciesOptions: urls.vueSelect.speciesOptions,
            breedOptions: urls.vueSelect.breedOptions,
            fields: [
                {
                    name: 'name',
                    sortField: 'name'
                },
                {
                    name: 'email',
                    title: 'Email Address'
                },
                {
                    name: 'birthdate'
                },
                {
                    name: 'gender'
                }
            ]
        };
    },
    // computed: {
    //     pagination_lower_limit () {
    //         return (this.curr_pagination_sec - 1) * 5;
    //     },
    //     pagination_upper_limit () {
    //         return this.curr_pagination_sec * 5;
    //     }
    // },
    watch: {

        curr_pagination_sec: {
            handler () {
                this.pagination_lower_limit = (this.curr_pagination_sec - 1) * 5;
                this.pagination_upper_limit = this.curr_pagination_sec * 5;
            }
        },
        last_page: {
            handler () {
                // console.log('last page', this.last_page);
                if (this.last_page > 1 && this.last_page < 6) {
                    this.pagination_sec_count = 1;
                } else if (this.last_page % 5 === 0) {
                    this.pagination_sec_count = parseInt(this.last_page / 5);
                    this.perfect_pagination = true;
                } else if (this.last_page % 5 !== 0) {
                    this.pagination_sec_count = parseInt(this.last_page / 5);
                    // alert('not perfect pag');
                    this.perfect_pagination = false;
                }
            }
        },
        damsYield: {
            handler () {
                if (this.damsYield === null) {
                    this.damsYield = '';
                }
                this.changeRange();
            }
        },
        species: {
            handler () {
                if (this.species === null) {
                    this.species = '';
                }
                this.loadDetails();
            }
        },
        breed: {
            handler () {
                if (this.breed === null) {
                    this.breed = '';
                }
                this.loadDetails();
            }
        },
        search: {
            handler () {
                if (this.search === null || this.search === undefined) {
                    this.search = '';
                }
                this.loadDetails();
            }
        }
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        nextPage () {
            if (this.current_page !== this.last_page) {
                this.current_page = this.current_page + 1;
                this.loadDetails();
            }
        },
        previousPage () {
            if (this.current_page !== 1) {
                this.current_page = this.current_page - 1;
                this.loadDetails();
            }
        },
        scrollToTop () {
            window.location = '#tableTop';
        },
        backClicked () {
            this.clearFilter();
            this.$emit('detailsClosed');
            this.detailsClicked = false;
            this.bullID = '';
            this.$router.push('/our-bulls/');
        },
        viewDetails (item) {
            this.detailsClicked = true;
            this.$emit('detailsClicked', this.detailsClicked);
            this.bullID = item;
        },
        clearFilter () {
            this.$emit('clear');
            this.to = '';
            this.from = '';
            this.species = '';
            this.breed = '';
            this.damsYield = '';
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.admin.bulls.list, {
                search: that.search,
                page: that.current_page,
                per_page: that.per_page,
                status: 'published',
                breed: that.breed,
                species: that.species,
                to: that.to,
                from: that.from
            });
            const json = response.data;
            if (json) {
                that.last_page = response.data.last_page;
                that.details = json.data;
                that.scrollToTop();
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.DataLoading = false;
        },
        changeRange () {
            if (this.damsYield === 1) {
                this.from = 2000;
                this.to = 4000;
            } else if (this.damsYield === 2) {
                this.from = 4000;
                this.to = 6000;
            } else if (this.damsYield === 3) {
                this.from = 6000;
                this.to = 8000;
            } else if (this.damsYield === 4) {
                this.from = 8000;
                this.to = 10000;
            } else if (this.damsYield === 5) {
                this.from = 10000;
                this.to = 10000000;
            } else {

            }
            this.loadDetails();
        },
        nextPaginationSection () {
            if (this.curr_pagination_sec < this.pagination_sec_count && this.perfect_pagination) {
                this.curr_pagination_sec += 1;
            } else if (!this.perfect_pagination && this.curr_pagination_sec < this.pagination_sec_count + 1) {
                this.curr_pagination_sec += 1;
            }
        },
        previousPaginationSection () {
            if (this.curr_pagination_sec > 1) {
                this.curr_pagination_sec -= 1;
            }
        },
        gotoPage (pageNo) {
            this.current_page = pageNo;
            this.loadDetails();
        }
    }
};
</script>

<style>
.info-img {
    width: 100%;
    border-radius: 8px;
}

@media (max-width: 992px) {
    .info-img {
        width: 100%;
    }

    .filter-heading {
        /*font-size: rem;*/
    }
}

@media screen and (max-width: 992px) {

    /*.c-table tr:first-child {*/
    /*    display: none !important;*/
    /*}*/
    .tableoverflowwrapper, .tableoverflowwrapper1 {
        border: 0;
        overflow: unset !important;
    }

    .c-table {
        border: 0;
    }

    .c-table th, .c-table thead {
        display: none;
    }

    .c-table tr, .c-table tr {
        height: auto;
        margin-bottom: 15px !important;
        display: block;
        clear: both;
        border: 1px solid #bfbfbf;
        border-radius: 8px;
        padding: 0 10px 0 10px;
    }

    .c-table tr::before, .c-table tr::before {
        content: "";
        display: block;
    }

    .c-table tr::after {
        content: "";
        display: block;
        clear: both;
    }

    .c-table td, .c-table td {
        display: block;
        position: relative;
        width: 50%;
        float: left;
        margin-top: 15px;
        text-align: left;
        padding-left: 0;
        word-break: break-all;
    }

    .c-table td[data-label="Transaction Particulars"],
    .c-table td[data-label="Transaction Remarks"] {
        width: 100%;
    }

    .c-table td {
        padding-left: 0 !important;

    }

    .c-table td::before {
        content: attr(data-label);
        position: absolute;
        top: -9px;
        left: 0;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #aaaaaa;
    }
}

.table-img {
    width: 100% !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
}

@media (min-width: 992px) {

    .flex-grow-0-lg {
        flex-grow: 0;
    }

    .table-img {
        width: 220px !important;
        padding-top: 15px !important;
    }
}

</style>
