<template>
    <div class="row mb-3">
        <div class="col-12 col-lg mb-3">
            <div class="fl-sm-c-lg-e w-100">
                <div class="fl-x fl-a-e fl-j-c h-100 w-80 m-0 ">
                    <img style=""
                         :src="img" alt="" class="w-100 m-0 border-r-2">
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="fl-x fl-a-c h-100">
                <div class="text-lg-left">
                    <p class="poppins-500 m-0 fs-5 mb-2 text-capitalize">{{ heading }}</p>
                    <p class="fs--1">{{ content }}</p>
                </div>
            </div>
        </div>
        <!-- <div class="fl-x">
            <div class="fl-x fl-a-e h-100 half-width m-0">
                <img :src="img" alt="" class="mr-2 w-100 m-0">
            </div>
            <div class="fl-y fl-j-e ">
                <p class="font-weight-bold roboto-bold m-0">{{ heading }}</p>
                <p class="">{{ content }}</p>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'ImageTextCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        img: {
            type: Image,
            default: null

        }
    }
};
</script>

<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.fl-sm-c-lg-e {
    display: flex;
    justify-content: center;
    @media(min-width: 992px) {
        justify-content: flex-end;
    }
}
</style>
