<template>
    <div style="min-height: 28rem; background-position: center center; background-size:cover;"
         class=" px-4 py-7 h-100 text-white card-contain bg-cover" :style="{'background-image':'url('+ bgImage +')'}">
        <div class="dark-overlay"></div>
        <div class="fl-y fl-a-c fl-j-e h-100 card-text">
            <p class="mb-0 text-capitalize">
                {{ content }}
            </p>
            <!--            <div class="fl-x fl-a-c w-100">-->
            <!--                <i class="fa fa-circle-o text-primary"></i>-->
            <!--                <a href="#" class="text-decoration-none">Read More</a>-->
            <!--            </div>-->
        </div>

    </div>
</template>

<script>
export default {
    name: 'PictureCard',
    props: {
        bgImage: {
            type: Image
        },
        content: {
            type: String,
            default: ''
        }
    }
};
</script>

<style>
.card-contain {
    position: relative;
    height: 100%;
    min-height: 420px;
}

.dark-overlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.35);*/
    background: linear-gradient(#e6646500, #000000d9);
}

.card-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 25px;
}
</style>
