<template>
    <data-loading-animation class="pt-5 pb-5" v-if="dataLoading"/>
    <div v-else class="bg-cover awards-bg">
        <div class="container py-6">
            <h2 class="text-center text-uppercase oswald-600 mb-5">Awards & Certificates</h2>
            <swiper v-if="details.length!==0" class="swiper pb-5" :options="swiperOption">
                <swiper-slide v-for="(item, i) in details" :key="i">
                    <div class="row">
                        <div class="col-12">
                            <image-text-card :heading="item.title" :content="item.description"
                                             :img="'/upload/' + item.file"></image-text-card>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <p v-else class="text-primary text-center">No awards to display.</p>
        </div>
    </div>
</template>
<script>
import ImageTextCard from './UI/ImageTextCard.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    components: { ImageTextCard, Swiper, SwiperSlide },
    name: 'AwardsAndCertifications',
    data () {
        return {
            dataLoading: false,
            details: '',
            swiperOption: {
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 2000,
                    autoplay: true
                },
                loop: true,
                speed: 1500,
                breakpoints: {
                    1200: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 2
                    },
                    580: {
                        slidesPerView: 1
                    },
                    320: {
                        slidesPerView: 1
                    }
                }
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.form(urls.admin.awards.list, {
                status: 'published'
            });
            const json = response.data;
            if (json) {
                that.details = json.data;
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        }
    }
};
</script>
<style>

</style>
