<template>
    <data-loading-animation class="pt-5 pb-5" v-if="dataLoading"/>
    <div v-else class="container py-5">
        <h2 class="text-center text-uppercase oswald-600 mb-5">State of Art Facility</h2>
        <swiper v-if="details.length!==0" class="swiper h-100 pb-5" :options="swiperOption">
            <swiper-slide v-for="(item, i) in details" :key="i">
                <picture-card :bgImage="'/upload/' + item.file" :content="item.title"/>
            </swiper-slide>
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <p v-else class="text-primary text-center">No facility to display.</p>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import PictureCard from './UI/PictureCard.vue';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'OurFacilities',
    title: 'Pagination',
    components: {
        Swiper,
        SwiperSlide,
        PictureCard
    },
    data () {
        return {
            dataLoading: false,
            details: '',
            swiperOption: {
                spaceBetween: 30,
                autoplay: {
                    delay: 3000,
                    autoplay: true
                },
                speed: 2000,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 2
                    },
                    580: {
                        slidesPerView: 2
                    },
                    320: {
                        slidesPerView: 1
                    }
                }
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.dataLoading = true;
            const response = await axios.form(urls.admin.facility.list, {
                status: 'published'
            });
            const json = response.data;
            if (json) {
                that.details = json.data;
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.dataLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
//   @import './base.scss';
</style>
